import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Select from "react-select";
import { requestPost } from "../set-apis";
import { Http } from "../http-dragon";
import { api } from "../url";
import { selectCreater } from "../utils/custom-button";
import { dateWithTime } from "../utils/date-formater";
import { OrderContext } from "../loginData/RealTimeOrderProvider";
import CurrentOrder from "../components/current-order";
import { toast } from "react-toastify";
import Confirmation from "../components/confirmation";

const Orders = () => {
  const { realTimeOrder, setRealTimeOrder } = useContext(OrderContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [restaurantsList, setRestaurantsList] = useState([]);
  const [restaurantValue, setRestaurantValue] = useState("");
  const [orderType, setOrderType] = useState("DINING");
  const [diningOrderList, setDiningOrderList] = useState([]);
  const [takeAwayOrderList, setTakeAwayOrderList] = useState([]);
  const [preOrderList, setPreOrderList] = useState([]);
  const [inCommingOrder, setInCommingOrder] = useState({});
  const [isInCommingOrder, setIsInCommingOrder] = useState(false);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState("");
  const [confirmationMsg,setConfirmationMsg] = useState("Are you sure? This action can't revert!!")
  const [isConfirmation,setIsConfirmation] = useState(false)
  const [orderBody,setOrderBody] = useState(null)
  const [loginData,setLoginData] = useState(null)

  useEffect(() => {
    let data =  JSON.parse(localStorage.getItem("user"))
    setLoginData(data)
    givePermit(data)

  }, [name]);

  useEffect(() => {
    if (realTimeOrder) {
      toast.info("Get New Order.");

      switch (realTimeOrder.orderType) {
        case "TAKE_AWAY":
          setTakeAwayOrderList([realTimeOrder, ...takeAwayOrderList]);
          break;
        case "DINING":
          setDiningOrderList([realTimeOrder, ...diningOrderList]);
        case "PRE_ORDER":
          setPreOrderList([realTimeOrder, ...preOrderList]);
      }
    }
  }, [realTimeOrder]);

  const givePermit =(data)=>{
    if(data?.role==="SUPER_ADMIN"){
      if (name) {
        const clear = setTimeout(() => {
          fetchRestaurant();
        }, 500);
        return () => clearTimeout(clear);
      }
    }else if(data?.role==="OWNER"){
      selectCreater(data?.restaurant);
      setRestaurantsList(data?.restaurant);
    }else if(data?.role==="MANAGER"){
  
      fetchOrdersPreOrder(data?.restaurant[0]?._id);
      fetchOrdersTakAway(data?.restaurant[0]?._id);
      fetchOrdersDining(data?.restaurant[0]?._id);
    }
  }

  const fetchRestaurant = async () => {
    Http.loader(true);
    const res = await requestPost(api.getRestaurantsByName, { search: name });
    Http.loader(false);
    console.log("res.data", res.data);

    if (!res.data) {
      setRestaurantsList([]);
    } else {
      selectCreater(res.data?.data);
      setRestaurantsList(res.data?.data);
    }
  };

  const fetchOrdersDining = async (restaurantID) => {
    const res = await requestPost(api.findOrdersByOrderType, {
      restaurantID,
      orderType: "DINING",
    });
    if (!res.data) {
      setDiningOrderList([]);
    } else {
      setDiningOrderList(res.data?.data);
    }
  };
  const fetchOrdersPreOrder = async (restaurantID) => {
    const res = await requestPost(api.findOrdersByOrderType, {
      restaurantID,
      orderType: "PRE_ORDER",
    });
    if (!res.data) {
      setPreOrderList([]);
    } else {
      setPreOrderList(res.data?.data);
    }
  };
  const fetchOrdersTakAway = async (restaurantID) => {
    const res = await requestPost(api.findOrdersByOrderType, {
      restaurantID,
      orderType: "TAKE_AWAY",
    });
    if (!res.data) {
      setTakeAwayOrderList([]);
    } else {
      setTakeAwayOrderList(res.data?.data);
    }
  };

  const dropdownChangeHandler = (e) => {
    setSelectedRestaurantId(e.value);
    setRestaurantValue(e);
    fetchOrdersPreOrder(e.value);
    fetchOrdersTakAway(e.value);
    fetchOrdersDining(e.value);
  };
const handleStatus = (e, orderID)=>{
  let orderStatus = e.target.value
  setOrderBody({orderStatus,orderID})
  if(orderStatus==="completed" || orderStatus==="cancelled"){
     setIsConfirmation(true)
  }else{
    hanleOrderStatus({orderStatus,orderID})
  }
}

  const hanleOrderStatus = async (body=null) => {
    const res = await requestPost(api.changeOrderStatus,body?body: orderBody);
    if (!res.data) {
    } else {
      fetchOrdersDining(selectedRestaurantId)
      fetchOrdersPreOrder(selectedRestaurantId)
      fetchOrdersTakAway(selectedRestaurantId)
    }
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaListAlt size="20" />
                        </span>
                      </span>
                      Orders
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="mt-5 restaurant-edit">
          {loginData?.role==="SUPER_ADMIN" || loginData?.role==="OWNER" ? <div className="row justify-content-center">
              <div className="col-12 col-md-4 ">
                <label htmlFor="applicant">
                  <b>Search Restaurant</b>
                </label>
                <Select
                  onInputChange={(e) => setName(e)}
                  inputValue={name}
                  options={restaurantsList}
                  value={restaurantValue}
                  onChange={dropdownChangeHandler}
                />
              </div>
            </div>:"" }
            <div className="row">
              <Tabs className="mt-5 sipstpswp">
                <div className="row justify-content-center ">
                  <div className="col-12 col-md-7">
                    <TabList>
                      <Tab>Pre-order</Tab>
                      <Tab>Dining</Tab>
                      <Tab>Takeaway</Tab>
                    </TabList>
                  </div>
                </div>
                <div className="my-5">
                  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Start & End time</th>
                                    <th>Party Size</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {preOrderList.length > 0 ? (
                                    preOrderList.map((item) => {
                                      return (
                                        <tr>
                                          <td>{item.user.name} </td>
                                          <td> {item.finalAmount}</td>
                                          <td>{item.cartTotal}</td>
                                          <td>{item.taxAndCharges}</td>
                                          <td></td>
                                          <td>{item.discountedAmount}</td>
                                          <td>
                                            {dateWithTime(item.startTime) +
                                              "  to  " +
                                              dateWithTime(item.endTime)}
                                          </td>
                                          <td>{item.partySize}</td>
                                          <td>
                                            <select
                                              name="orderStatus"
                                              id="cars"
                                              className="form-select"
                                              value={item.orderStatus}
                                              onChange={(e) =>
                                                handleStatus(e, item._id)
                                              }
                                            >
                                              <option value="pending">
                                                pending
                                              </option>
                                              <option value="confirm">
                                                confirm
                                              </option>
                                              <option value="preparing">
                                                preparing
                                              </option>
                                              <option value="served">
                                                served
                                              </option>
                                              <option value="completed">
                                                completed
                                              </option>
                                              <option value="prepared">
                                                prepared
                                              </option>
                                              <option value="cancelled">
                                                cancelled
                                              </option>
                                            </select>
                                          </td>
                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={handleShow}
                                            >
                                              {" "}
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <p>No Orders</p>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Table no</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {diningOrderList.length > 0 ? (
                                    diningOrderList.map((item) => {
                                      return (
                                        <tr>
                                          <td>{item.user.name} </td>
                                          <td> {item.finalAmount}</td>
                                          <td>{item.cartTotal}</td>
                                          <td>{item.taxAndCharges}</td>
                                          <td></td>
                                          <td>{item.discountedAmount}</td>
                                          <td>
                                            {item.tableNo ? item.tableNo : "NA"}
                                          </td>
                                          <td>
                                            <select
                                              name="orderStatus"
                                              id="cars"
                                              className="form-select"
                                              value={item.orderStatus}
                                              onChange={(e) =>
                                                handleStatus(e, item._id)
                                              }
                                            >
                                              <option value="pending">
                                                pending
                                              </option>
                                              <option value="confirm">
                                                confirm
                                              </option>
                                              <option value="preparing">
                                                preparing
                                              </option>
                                              <option value="served">
                                                served
                                              </option>
                                              <option value="completed">
                                                completed
                                              </option>
                                              <option value="prepared">
                                                prepared
                                              </option>
                                              <option value="cancelled">
                                                cancelled
                                              </option>
                                            </select>
                                          </td>
                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={handleShow}
                                            >
                                              {" "}
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <p>No Orders</p>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {takeAwayOrderList.length > 0 ? (
                                    takeAwayOrderList.map((item) => {
                                      return (
                                        <tr>
                                          <td>{item.user.name} </td>
                                          <td>₹ {item.finalAmount}</td>
                                          <td>₹ {item.cartTotal}</td>
                                          <td>₹ {item.taxAndCharges}</td>
                                          <td></td>
                                          <td>₹ {item.discountedAmount}</td>
                                          <td>
                                            <select
                                              name="orderStatus"
                                              id="cars"
                                              className="form-select"
                                              value={item.orderStatus}
                                              onChange={(e) =>
                                                handleStatus(e, item._id)
                                              }
                                            >
                                              <option value="pending">
                                                pending
                                              </option>
                                              <option value="confirm">
                                                confirm
                                              </option>
                                              <option value="preparing">
                                                preparing
                                              </option>
                                              <option value="served">
                                                served
                                              </option>
                                              <option value="completed">
                                                completed
                                              </option>
                                              <option value="prepared">
                                                prepared
                                              </option>
                                              <option value="cancelled">
                                                cancelled
                                              </option>
                                            </select>
                                          </td>

                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={handleShow}
                                            >
                                              {" "}
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <p>No Orders</p>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </section>
        </div>

        {/* items details popup start*/}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Items detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover className="text-center">
              <tr className="bg-primary text-white">
                <th>Item</th>
                <th>Price(₹)</th>
              </tr>
              <tr>
                <td>aloo</td>
                <td>56</td>
              </tr>
              <tr>
                <td>aloo</td>
                <td>56</td>
              </tr>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        {/* items details end*/}
      </main>
      <CurrentOrder
        show={isInCommingOrder}
        setShow={setIsInCommingOrder}
        data={inCommingOrder}
      />
      <Confirmation show={isConfirmation} setShow={setIsConfirmation} message={confirmationMsg} deleteData={hanleOrderStatus}/>
    </>
  );
};

export default Orders;
