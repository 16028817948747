import axios from 'axios';
import { React, useEffect, useState } from 'react';
import { RiHotelFill } from "react-icons/ri";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { api, baseUrl } from '../url';
import { validateField } from '../utils/custom-validations';
import { useLocation ,useNavigate} from 'react-router-dom';
import { Http } from '../http-dragon';


const Restaurant_Details = () => {
  const navigate = useNavigate()
  const location = useLocation()
  let initialValue = {
    name: "",
    gst: "",
    fssai: "",
    latitude: "",
    longitude: "",
    address: "",
    phoneno: "",
    tablesQuantity: "",
    gstImages: null,
    fssaiImages: null,
    owner: "",
    openingTime:"",
    closingTime:"",
    heroImage:null,
    charges:"",
    gstPercentage:""

  }
  const [ownerList, setOwnerList] = useState([])
  const [formData, setFormData] = useState(initialValue)
  const [formError, setFormError] = useState(initialValue)


  useEffect(() => {
    fetchOwner()
    if (location?.state?._id) {
      fetchRestaurantDetail()
    }else if(location?.state?.ownerId){
        setOwnerList([{name:location?.state?.name,_id:location?.state?.ownerId}])
        setFormData({
          ...formData,
          ["owner"]: location?.state?.ownerId
        })
    }
  }, [])
  const fetchRestaurantDetail = async () => {
    Http.loader(true)
    try {
      axios.post(baseUrl + api.getSingleRestaurant, { restaurantID: location?.state?._id }).then(res => {
        console.log("aa", res.data.restaurant);
        let data = res.data.restaurant
        let singleData = {}
        singleData.name = data?.name
        singleData.gst = data?.gst
        singleData.fssai = data?.fssai
        singleData.address = data?.address
        singleData.phoneno = data?.contact
        singleData.tablesQuantity = data?.tablesQuantity
        singleData.longitude = data?.location?.coordinates[0]
        singleData.latitude = data?.location?.coordinates[1]
        singleData.owner = data?.owner?._id
        singleData.openingTime = data?.openingTime
        singleData.closingTime = data?.closingTime
        singleData.charges = data?.charges
        singleData.gstPercentage = data?.gstPercentage
        Http.loader(false)
        setFormData(singleData)
      })
    } catch (error) {
      Http.loader(false)
      setOwnerList([])
      toast.error(error.response.data.msg)
    }
    Http.loader(false)
  }
  const fetchOwner = async () => {
    Http.loader(true)
 
      axios.get(baseUrl + api.getOwners).then(res => {
        Http.loader(false)
        setOwnerList(res.data.owners)
        selectCreater(res.data.owners)

      }). catch (error=> {
      Http.loader(false)
      setOwnerList([])
      toast.error(error.response.data.msg)
    })
  }

  const selectCreater = (array, id, name) => {
    array.forEach(element => {
      element.label = element.name
      element.value = element._id
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === "fssaiImages" || name === "gstImages" || name==="heroImage") {
      setFormData({
        ...formData,
        [name]: e.target.files[0]
      });
    } else if (name === "gst" && value.length < 16) {
      setFormData({
        ...formData,
        [name]: value
      });
    }
    else if (name === "charges" && name === "gstPercentage") {
      setFormData({
        ...formData,
        [name]: Number(value)
      });
    }
    else if (name === "fssai" && value.length < 15) {
      setFormData({
        ...formData,
        [name]: value
      });
    }
    else if (name === "phoneno" && value.length < 11 && !isNaN(value)) {
      setFormData({
        ...formData,
        [name]: value
      });
    }



  };

  const submitData = async () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if((location?.state && key ==="fssaiImages") ||  (location?.state && key ==="gstImages")||  (location?.state && key ==="heroImage")){
        // idProof = ""
      }else{
        if (error) {
        newErrors[key] = error;
      }}
    });
 console.log("newErrors",newErrors);
    if (Object.keys(newErrors).length === 0) {
      let formBody = new FormData();
      formBody.append("name", formData.name);
      formBody.append("contact", formData.phoneno);
      formBody.append("owner", formData.owner);
      formBody.append("gst", formData.gst);
      formBody.append("gstImages", formData.gstImages);
      formBody.append("fssai", formData.fssai);
      formBody.append("fssaiImages", formData.fssaiImages );
      formBody.append("latitude", formData.latitude);
      formBody.append("longitude", formData.longitude);
      formBody.append("address", formData.address);
      formBody.append("tablesQuantity", formData.tablesQuantity);
      formBody.append("openingTime", formData.openingTime);
      formBody.append("closingTime", formData.closingTime);
      formBody.append("restaurantId", location?.state?._id);
      formBody.append("heroImage", formData.heroImage);
      formBody.append("gstPercentage", formData.gstPercentage);
      formBody.append("charges", formData.charges);
      Http.loader(true)
      try {
        let res = null
        if(location?.state?._id){
           res = await axios.post(baseUrl + api.updateRestaurant, formBody)
           Http.loader(false)
        }else{
           res = await axios.post(baseUrl + api.restaurant, formBody)
           Http.loader(false)
           navigate("/restaurant-images",{state:{_id:res.data?.id}})
        }
        toast.success(res.data.message)
        Http.loader(false)
        setFormData(initialValue)
        setFormError(initialValue);

      } catch (error) {
        Http.loader(false)
        toast.error(error.response?.data?.message)
        setFormError(initialValue);
      }
    } else {
      Http.loader(false)
      setFormError(newErrors);
    }
  }

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <RiHotelFill size='20' />

                        </span>
                      </span>
                      Restaurant Details
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="restaurant mt-4 mb-5 px-4">
            <div>

              <div className="row  mt-4 shadow-search">
                <div className="col-12 col-md-4 ">
                  <label htmlFor="applicant"><b>Select Owner</b></label>
                  <select class="form-select" aria-label="Default select example" onChange={handleChange} name='owner' value={formData.owner}>
                    <option value="">...Select Owner</option>
                    {ownerList.map((item)=>{
                      return <option value={item._id}>{item.name}</option>
                     
                    })}
                  </select>

                  <small className='text-danger'>{formError?.owner}</small>
                </div>
                <div className="col-12 col-md-4 ">
                  <label htmlFor="applicant"><b>Restaurant Name</b></label>
                  <input type="text" className="form-control" name='name' value={formData.name} onChange={handleChange} />
                  <small className='text-danger'>{formError?.name}</small>
                </div>
                <div className="col-12 col-md-4 ">
                  <label htmlFor="applicant"><b>Hero Image</b></label>
                  <input type="file" className="form-control" name='heroImage'  onChange={handleChange} />
                  <small className='text-danger'>{formError?.heroImage}</small>
                </div>
                <div className="col-12 col-md-4 mt-3">
                  <label htmlFor="applicant"><b>Contact Number</b></label>
                  <input type="text" className="form-control" name='phoneno' value={formData.phoneno} onChange={handleChange} />
                  <small className='text-danger'>{formError?.phoneno}</small>
                </div>
                <div className="col-12 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>GSTIN</b></label>
                  <input type="text" className="form-control" name='gst' value={formData.gst} onChange={handleChange} />
                  <small className='text-danger'>{formError?.gst}</small>
                </div>
                <div className="col-12 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>GSTIN Upload</b></label>
                  <input type="file" className="form-control" name='gstImages' onChange={handleChange} />
                <small className='text-danger'>{formError?.gstImages}</small>
                </div>
                <div className="col-12 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>FSSAI</b></label>
                  <input type="text" className="form-control" name='fssai' value={formData.fssai} onChange={handleChange} />
                  <small className='text-danger'>{formError?.fssai}</small>
                </div>
                <div className="col-12 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>FSSAI Upload</b></label>
                  <input type="file" className="form-control" name='fssaiImages' onChange={handleChange} />
                  <small className='text-danger'>{formError?.fssaiImages}</small>
                </div>

                <div className="col-6 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>Latitude</b></label>
                  <input type="text" className="form-control" name='latitude' value={formData.latitude} onChange={handleChange} />
                  <small className='text-danger'>{formError?.latitude}</small>
                </div>
                <div className="col-6 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>Longitude</b></label>
                  <input type="text" className="form-control" value={formData.longitude} name='longitude' onChange={handleChange} />
                  <small className='text-danger'>{formError?.longitude}</small>
                </div>
                <div className="col-6 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>Number of Table</b></label>
                  <input type="text" className="form-control" value={formData.tablesQuantity} name='tablesQuantity' onChange={handleChange} />
                  <small className='text-danger'>{formError?.tablesQuantity}</small>
                </div>
                <div className="col-6 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>Opening Time</b></label>
                  <input type="time" className="form-control" value={formData.openingTime} name='openingTime' onChange={handleChange} />
                  <small className='text-danger'>{formError?.openingTime}</small>
                </div>
                <div className="col-6 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>Closing Time</b></label>
                  <input type="time" className="form-control" value={formData.closingTime} name='closingTime' onChange={handleChange} />
                  <small className='text-danger'>{formError?.closingTime}</small>
                </div>
                <div className="col-6 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>Charges</b></label>
                  <input type="text" className="form-control" value={formData.charges} name='charges' onChange={handleChange} />
                  <small className='text-danger'>{formError?.charges}</small>
                </div>
                <div className="col-6 col-md-4  mt-3">
                  <label htmlFor="applicant"><b>Gst Percentage</b></label>
                  <input type="text" className="form-control" value={formData.gstPercentage} name='gstPercentage' onChange={handleChange} />
                  <small className='text-danger'>{formError?.gstPercentage}</small>
                </div>
                <div className="col-12 col-md-6  mt-3">
                  <label htmlFor="applicant"><b>Address</b></label>
                  <textarea name="address" id="" className='form-control' value={formData.address} onChange={handleChange}></textarea>
                  <small className='text-danger'>{formError?.address}</small>
                </div>

                <div class="col-12 col-md-2 align-self-center mt-4 pt-2 text-center">
                  <button class="bg-gradient-primary text-white p-2 custom-button padding-btn " onClick={submitData}>Submit</button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
export default Restaurant_Details;