import { React, useEffect, useState } from "react";
import { MdPhotoLibrary, MdClose } from "react-icons/md";
import { FaTrash, FaUpload } from "react-icons/fa";
import { useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import { api, baseUrl } from "../url";
import { toast } from "react-toastify";
import { successToast } from "./logics/utils";

const Restaurant_Images = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  useEffect(() => {
    if(location.state._id){
      fetchRestaurantImage();
    }else{
      navigate("/restaurant-lists")
    }
  }, []);

  const fetchRestaurantImage = async () => {
    try {
      axios
        .post(baseUrl + api.getRestaurantImages, {
          restaurantID: location.state._id,
        })
        .then((res) => {
          console.log(res.data.images);
          setUploadedImages(res.data.images);
        });
    } catch (error) {
      setUploadedImages([]);
      toast.error(error.response.data.msg);
    }
  };

  const selectImages = (e) => {
    const files = Array.from(e.target.files);
    const tempArray = [];
    for (let a of files) {
      tempArray.push(URL.createObjectURL(a));
    }
    setPreviewImages(tempArray);
    setImages(files);
  };

  const deleteImage = async (_id) => {
    try {
      axios
        .post(baseUrl + api.deleteRestaurantImage, { imageID: _id })
        .then((res) => {
          fetchRestaurantImage();
          successToast(res)
        });
    } catch (error) {
      // setUploadedImages([])
      toast.error(error.response.data.msg);
    }
  };

  const uploadImages = () => {
    let formBody = new FormData();
    images.forEach((element) => {
      formBody.append("images", element);
    });
    formBody.append("restaurantID", location.state._id);
    try {
      axios.post(baseUrl + api.uploadRestaurantImages, formBody).then((res) => {
        console.log("success")
        fetchRestaurantImage();
        navigate("/category",{state:{categoryId:res.data?.id,_id:location.state._id}})
        setPreviewImages([]);
      });
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <MdPhotoLibrary size="20" />
                        </span>
                      </span>
                      Restaurant Images
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4 my-5">
            <div>
              <div className="row justify-content-center">
                <div className="col-6">
                  <div className="upload-section py-3 d-flex ">
                    <FaUpload className="fs-2 me-3 align-self-center" />
                    <input
                      type="file"
                      name=""
                      multiple
                      id=""
                      className="input-style bg-white"
                      onChange={selectImages}
                      accept="image/*"
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="upload-section py-3 d-flex ">
                    <button className="btn btn-success" onClick={uploadImages}>
                      Upload images
                    </button>
                  </div>
                </div>
              </div>
            
              {uploadedImages.length > 0 && (
                <>
                  <h4>Added Images</h4>
                  <div className="row  my-4 shadow-search light-b">
                    {uploadedImages.map((item) => {
                      return (
                        <div className="col-md-3  " key={item?.url}>
                          <div className="image-area">
                            <img
                              src={item?.url}
                              alt="Preview"
                              className="img-fluid"
                            />
                            <div
                              className="remove-image"
                              onClick={() => deleteImage(item._id)}
                            >
                              <MdClose className="text-white" />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default Restaurant_Images;
