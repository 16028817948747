import { React, useState } from 'react';
import { FaHotel, FaEdit, FaChair, FaUserPlus } from "react-icons/fa";
import { RiHotelFill } from "react-icons/ri";

import { BiDetail } from "react-icons/bi";

import { Link } from 'react-router-dom';
const Restaurant_Onboarding = () => {


  return (
    <>

      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaHotel size='20' />

                        </span>
                      </span>
                      Restaurant Onboarding
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className='mt-5 restaurant-edit'>
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body d-flex justify-content-between">

                    <h5><span><FaUserPlus className='text-purple me-2' /></span><span className='align-middle'>Owner Onboarding</span></h5>
                    <Link to="/owner-details"><FaEdit size='20' /></Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body d-flex justify-content-between">

                    <h5><span><RiHotelFill className='text-purple me-2' /></span><span className='align-middle'>Restaurant Onboarding</span></h5>
                    <Link to="/restaurant-details"><FaEdit size='20' /></Link>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-4">
                <div className="card">
                  <div className="card-body d-flex justify-content-between">

                    <h5><span><BiDetail className='text-purple me-2' /></span><span className='align-middle'>Restaurant Lists</span></h5>
                    <Link to='/restaurant-lists'><FaEdit size='20' /></Link>
                  </div>
                </div>
              </div> */}

            </div>


          </section>




        </div>
      </main>
    </>
  );
}
export default Restaurant_Onboarding;