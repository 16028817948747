import { socket } from "../../utils/socket-connection";

export const socketRegister = (token,setRealTimeOrder) =>{
    socket.connect()
    socket.on("connect",()=>{
      socket.emit("register",token)
    })
    socket.on("ORDER-RECIEVED",(order)=>{
      console.log("orderrsss", order);
      setRealTimeOrder(order)
    })

}

