import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

function CurrentOrder({ show, setShow, data }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data?.user?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">S. no.</th>
                <th scope="col">Dish Name</th>
                <th scope="col">Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total </th>
              </tr>
            </thead>
            <tbody>
              {data.items?.length > 0 && data.items.map((item,i)=>{
                return   <tr>
                <th scope="row">{i+1}</th>
                <td>{item.dishName}</td>
                <td>{item.price}</td>
                <td>{item.quantity}</td>
                <td>{item.totalPrice}</td>
              </tr>
              })}
            
             
            </tbody>
          </table>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Confirm Order</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CurrentOrder;
