import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import * as htmlToImage from "html-to-image";
import QRCode from "react-qr-code";
import download from "downloadjs";

function Generate_qrcode({ show, handleClose, tableNo, restaurantID }) {
  let qrcodeURL = `https://bhukkads.in/restaurant?id=${restaurantID}&tableno=${tableNo}`;
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [foregroundColor, setForegroundColor] = useState("#000000");
  const [qrcodeSize, setQrcodeSize] = useState(256);

  const downloadQrCode = () => {
    const qrCodeElement = document.getElementById("qr-code");
    htmlToImage
      .toPng(qrCodeElement)
      .then((dataUrl) => {
        download(dataUrl, "qrcode.png");
      })
      .catch((err) => {
        console.error("Error generating QR code image");
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Items detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <input
            className="form-control m-2"
            type="text"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
            placeholder="Background color"
          />

          <input
            className="form-control m-2"
            type="text"
            value={foregroundColor}
            onChange={(e) => setForegroundColor(e.target.value)}
            placeholder="Foreground color"
          />

          <input
            className="form-control m-2"
            type="number"
            value={qrcodeSize}
            onChange={(e) => setQrcodeSize(e.target.value)}
            placeholder="Size of Qr-code"
          />

          <div className="text-center">
            <div
              id="qr-code"
              style={{ backgroundColor: backgroundColor, padding: "10px" }}
            >
              {qrcodeURL && (<>
               <p><b>Table No - {tableNo}</b></p> 
                <QRCode
                  value={qrcodeURL}
                  size={qrcodeSize}
                  bgColor={backgroundColor}
                  fgColor={foregroundColor}
                /></>
              )}
            </div>
            <div className="mt-2">
              <button className="btn btn-success" onClick={downloadQrCode}>
                Download
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default Generate_qrcode;
