import { React, useState, useEffect } from 'react';
import { FaArrowAltCircleRight, FaEdit, FaTrash } from "react-icons/fa";
import { MdMenuBook } from "react-icons/md";
import { MDBDataTable } from 'mdbreact';
import Select from 'react-select';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api, baseUrl } from '../url';
import { selectCreater } from '../utils/custom-button';
import { Http } from '../http-dragon';
const Menu = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [subcategoryName, setSubCategoryName] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [description , setDescription ] = useState("")
    const [categoryList, setCategoryList] = useState([])
    const [subCategoryList, setSubCategoryList] = useState([])
    const [price, setPrice] = useState("")
    const [menu, setMenu] = useState("")
    const [menuList, setMenuList] = useState([])
    useEffect(() => {
        
        if(location.state._id){
            fetchCategory()
            fetchSubCategory()
            fetchMenu()
          }else{
            navigate("/restaurant-lists")
          }
    }, [])
    const fetchMenu = () => {
        Http.loader(true)
        try {
            axios.post(baseUrl + api.getMenuByRestaurant, { restaurantID: location.state._id }).then(res => {
                setMenuList(res.data?.data)
                Http.loader(false)
                addButton(res.data?.data)
            })

        } catch (error) {
            Http.loader(false)
            setMenuList([])
            toast.error(error.response?.data?.message)
        }
        Http.loader(false)
    }
    const fetchCategory = () => {
        Http.loader(true)
        try {
            axios.post(baseUrl + api.getCategories, { restaurantID: location.state._id }).then(res => {
                setCategoryList(res.data?.categories)
                // addButton(res.data?.categories)
                Http.loader(false)
                selectCreater(res.data?.categories)

            })

        } catch (error) {
            Http.loader(false)
            setCategoryList([])
            toast.error(error.response?.data?.message)
        }
        Http.loader(false)
    }
    const fetchSubCategory = (e) => {
        setCategoryName(e)
        Http.loader(true)
        try {
            axios.post(baseUrl + api.getSubCategories, { categoryID: e.value }).then(res => {
                setSubCategoryList(res.data?.data)
                Http.loader(false)
                selectCreater(res.data?.data)
                fetchMenu()
            })

        } catch (error) {
            Http.loader(false)
            setCategoryList([])
            toast.error(error.response?.data?.message)
        }
        Http.loader(false)
    }

    const addButton = (array) => {
        array.forEach(element => {
            element.delete = <div className="btn btn-danger pt-0 pb-1"><FaTrash /></div>
            element.edit = <div className="btn btn-primary pt-0 pb-1"><FaEdit /></div>
        });
    }
    const addMenuCategory = () => {
        console.log("categoryName", location.state);
        if (!menu) {
            toast.error("Enter sub menu name..!")
            return
        }
        if (!description) {
            toast.error("Enter sub menu name..!")
            return
        }
        if (!categoryName) {
            toast.error("Enter category name..!")
            return
        }
      
        if (!price) {
            toast.error("Enter Price..!")
            return
        }
        let reqBody = {
            dishName: menu,
            description,
            price,
            restaurantId: location.state._id,
            categoryId: categoryName.value,
            subCategoryId:subcategoryName.value
        }
        try {
            console.log("success");
            Http.loader(true)
            axios.post(baseUrl +api.addMenuDish, reqBody).then(res => {
                toast.success(res.data?.message)
                Http.loader(false)
                fetchMenu()
                setCategoryName("")
                setSubCategoryName("")
                setMenu("")
                setPrice("")
               
            })

        } catch (error) {
            Http.loader(false)
            console.log("errror");
            toast.error(error.response?.data?.message)
        }
    }

    const menuHandler = (e) => {
        setMenu(e.target.value)
    }
    const descriptionHandler = (e) => {
        setDescription(e.target.value)
    }
    const goToMenuImage = ()=>{
        navigate("/menu-images",{state:{_id:location.state._id}})
    }
    const priceHandler = (e) => {
        setPrice(e.target.value)
    }
    const data = {
        columns: [
            {
                label: 'Date',
                field: 'createdAt',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Item name',
                field: 'dishName',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Category',
                field: 'category',
                sort: 'asc',
                width: 200
            },

           
            {
                label: 'Price (₹)',
                field: 'price',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Edit',
                field: 'edit',
                sort: 'asc',
                width: 150
            },


        ],
        rows:menuList
    };
    return (
        <>
            <main id="main">
                <div className="content-wrapper">
                    <div className="page-nav">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="page-header ms-2">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <span>
                                                    <MdMenuBook size='20' />

                                                </span>
                                            </span>
                                            Menu
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className='my-5 Menu'>

                        <div className="row justify-content-center px-4">
                            <div className="col-md-12 shadowc bg-white py-3 rounded">

                                <div className="row text-center">
                                    <div className=" col-3 ">
                                        <label htmlFor="Item"><b>Item name</b> <span className='text-danger'>*</span></label>
                                        <input type="text" className='form-control' name='menu' value={menu} onChange={menuHandler} />
                                    </div>
                                   
                                    <div className="col-3">
                                        <label><b>Category <span className='text-danger'>*</span></b></label>
                                        <Select
                                            options={categoryList}
                                            value={categoryName}
                                            onChange={fetchSubCategory}
                                        />
                                    </div>
                                    <div className="col-3 ">
                                        <label><b>Sub Category</b></label>
                                        <Select
                                            options={subCategoryList}
                                            value={subcategoryName}
                                            onChange={setSubCategoryName}
                                        />
                                    </div>
                                    <div className=" col-md-3 ">
                                        <label htmlFor="price"><b>Price</b> <span className='text-danger'>*</span></label>
                                        <input type="text" className='form-control' name='price' value={price} onChange={priceHandler} />
                                    </div>

                                    <div className="col-6 mt-3">
                                        <label htmlFor="Item"><b>Decription</b> <span className='text-danger'>*</span></label>
                                        <textarea rows={3} cols={3} className='form-control'  value={description} onChange={descriptionHandler}></textarea>
                                    </div>

                                    <div class="col-md-1 align-self-center mt-4 pt-2 text-center" onClick={addMenuCategory}>
                                        <div class="bg-gradient-primary text-white p-2 custom-button  ">Add +</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
             
                        <section className='px-4'>
                        <div className="row justify-content-end">
                            <div className="col-4 text-end mb-3" onClick={goToMenuImage}>
                                <div class="sub-btn" >Add Menu Images<FaArrowAltCircleRight /> </div>
                            </div>
                                <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                                    <MDBDataTable
                                        data={data}
                                        responsive
                                    />

                                </div>
                            </div>
                        </section>
                    </div>
            </main>
        </>
    );
}
export default Menu;