import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Restaurant_Onboarding from "./pages/restaurant-onboarding";
import Restaurant_Edit from "./pages/restaurant-edit";
import Sitting from "./pages/sitting";
import Restaurant_Details from "./pages/restaurant-details";
import Restaurant_List from "./pages/restaurant-lists";
import Owner_Details from "./pages/owner-details";
import Menu from "./pages/menu";
import Category from "./pages/category";
import Sub_Category from "./pages/sub-category";
import Restaurant_Images from "./pages/restaurant-images";
import Owner_List from "./pages/owner-lists";
import Menu_Images from "./pages/menu-images";
import GlobalCategories from "./pages/global-categories";
import EditAddRestaurantGlobalCat from "./pages/edit-add-restaurant-global-cat";
import Level2 from "./pages/level-2";
import AddLevel2 from "./pages/add-level-2";
import Offer from "./pages/offer";
import CreateManager from "./pages/craete-manager";
import Orders from "./pages/orders";
import { useContext, useEffect, useState } from "react";
import TimeSlots from "./pages/slot-page";
import { UserLoginContext } from "./loginData/UserLoginProvider";
import OrderHistory from "./pages/order-history";
import { authenticate } from "./auth/authenticate";



const AppRoutes = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState(null);
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"));
    if (!data) {
      navigate("/login");
    }
    setLoginData(data);
  }, []);

  
  return (
    <Routes>
      {/* <Route
        element={<EditAddRestaurantGlobalCat />}
        path="/add-update-global-category"
      /> */}

      {/* <Route element={<Dashboard />} path="/" /> */}
      {/* <Route element={<GlobalCategories />} path="/global-categories" /> */}

      {authenticate(
        "/add-update-global-category",
        <EditAddRestaurantGlobalCat />
      )}
      {authenticate("/global-categories", <GlobalCategories />)}
      {authenticate("/", <Dashboard />)}
      {authenticate("/restaurant-edit", <Restaurant_Edit />)}
      {authenticate("/sitting", <Sitting />)}
      {authenticate("/time-slots", <TimeSlots />)}
      {authenticate("/restaurant-lists", <Restaurant_List />)}
      {authenticate("/restaurant-details", <Restaurant_Details />)}
      {authenticate("/owner-details", <Owner_Details />)}
      {authenticate("/menu", <Menu />)}
      {authenticate("/menu-images", <Menu_Images />)}
      {authenticate("/category", <Category />)}
      {authenticate("/sub-category", <Sub_Category />)}
      {authenticate("/restaurant-images", <Restaurant_Images />)}
      {authenticate("/owner-lists", <Owner_List />)}
      {authenticate("/level-2", <Level2 />)}
      {authenticate("/add-level-2", <AddLevel2 />)}
      {authenticate("/offer", <Offer />)}
      {authenticate("/create-manager", <CreateManager />)}
      {authenticate("/orders", <Orders />)}
      {authenticate("/order-history", <OrderHistory />)}
      {authenticate("/restaurant-onboarding", <Restaurant_Onboarding />)}
      {/*<Route element={<Restaurant_Onboarding />}  path="/restaurant-onboarding"  />
      <Route element={<Restaurant_Edit />} path="/restaurant-edit" /> 
      <Route element={<Sitting />} path="/sitting" />
      <Route element={<TimeSlots />} path="/time-slots" />
      <Route element={<Restaurant_List />} path="/restaurant-lists" /> 
      <Route element={<Restaurant_Details />} path="/restaurant-details" />
      <Route element={<Owner_Details />} path="/owner-details" />
      <Route element={<Menu />} path="/menu" />
      <Route element={<Menu_Images />} path="/menu-images" />
      <Route element={<Category />} path="/category" />
      <Route element={<Sub_Category />} path="/sub-category" />
      <Route element={<Restaurant_Images />} path="/restaurant-images" />
      <Route element={<Owner_List />} path="/owner-lists" />
      <Route element={<Level2 />} path="/level-2" />
      <Route element={<AddLevel2 />} path="/add-level-2" />
      <Route element={<Offer />} path="/offer" />
      <Route element={<CreateManager />} path="/create-manager" />
      <Route element={<Orders />} path="/orders" />
      <Route element={<OrderHistory />} path="/order-history" />*/}
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default AppRoutes;
