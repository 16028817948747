import React, { useEffect, useState } from "react";

import { FaHome, FaHotel, FaListAlt, FaUserAlt } from "react-icons/fa";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { BiDetail } from "react-icons/bi";

const Sidebar = () => {
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState(null);
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"));
    if (data) {
      setLoginData(data);
    }
  }, []);

  const editOwner = () => {
    navigate("/restaurant-edit", {
      state: { _id: loginData.restaurant[0]?._id },
    });
  };
  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink
              to="/"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <FaHome />
              </span>
              <span className="menu-title">Dashboard</span>
            </NavLink>
          </li>
          {loginData?.role === "SUPER_ADMIN" && (
            <>
              <li className="nav-item">
                <NavLink
                  to="/restaurant-onboarding"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <FaHotel />
                  </span>
                  <span className="menu-title">Restaurant Onboarding</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/owner-lists"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <FaUserAlt />
                  </span>
                  <span className="menu-title">Owner Lists</span>
                </NavLink>
              </li>
            </>
          )}
          {(loginData?.role === "SUPER_ADMIN" ||
            loginData?.role === "OWNER") && (
            <li className="nav-item">
              <NavLink
                to="/restaurant-lists"
                activeClassName="nav-link"
                className="d-flex nav-link-c"
              >
                <span className="menu-icon">
                  <BiDetail />
                </span>
                <span className="menu-title">Restaurant Lists</span>
              </NavLink>
            </li>
          )}

          <li className="nav-item">
            <div
              onClick={editOwner}
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <BiDetail />
              </span>
              <span className="menu-title">Dishes Manage</span>
            </div>
          </li>
          {loginData?.role === "SUPER_ADMIN" && (
            <>
              <li className="nav-item">
                <NavLink
                  to="/global-categories"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <FaUserAlt />
                  </span>
                  <span className="menu-title">Global categories</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/level-2"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <FaUserAlt />
                  </span>
                  <span className="menu-title">Level 2 categories</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/add-level-2"
                  activeClassName="nav-link"
                  className="d-flex nav-link-c"
                >
                  <span className="menu-icon">
                    <FaUserAlt />
                  </span>
                  <span className="menu-title">Add Level 2 categories</span>
                </NavLink>
              </li>
            </>
          )}
          <li className="nav-item">
            <NavLink
              to="/orders"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <FaListAlt />
              </span>
              <span className="menu-title">Orders</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/order-history"
              activeClassName="nav-link"
              className="d-flex nav-link-c"
            >
              <span className="menu-icon">
                <FaListAlt />
              </span>
              <span className="menu-title">Order History</span>
            </NavLink>
          </li>
          {/* <li className="nav-item drop" >
            <Link className="nav-link collapsed navc" onClick={() => { setToggle(!toggle) }}>
            <span className="menu-icon"><FaUpload /></span>            
              <span className='ps-3 main-sidenav'>Upload</span>
              <FaChevronDown className={toggle ? "ms-8  dshow d-none" : "ms-8  dshow "} />
              <FaChevronUp className={toggle ? "ms-8  ddshow collapseshow " : "ms-8  ddshow collapseshow d-none"} />
            </Link>
            <ul id="components-nav" className={toggle ? `nav-content collapse show` : `nav-content collapse`} data-bs-parent="#sidebar-nav">
              <li className='ps-3'>
                <NavLink to="/upload-master-files" className="nav-link ">
                <span className="menu-icon"> <FaClipboardCheck className="nav-icon" /></span>  <span className='ps-2'>Upload Master Files</span>
                </NavLink>
              </li>
              <li className='ps-3'>
                <NavLink to="/portfolio-review" activeClassName="nav-link ">
                <span className="menu-icon">  <FaRandom className="nav-icon" /></span><span className='ps-2'>Upload Transaction Files</span>
                </NavLink>
              </li>
              <li className='ps-3'>
                <NavLink to="/jettison" activeClassName="nav-link ">
                <span className="menu-icon"> <TfiControlEject  size={25} /> </span><span className='ps-2'>Upload NAV</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item" >
            <NavLink to="/sipstpswp"  className="d-flex nav-link-c">
            <span className="menu-icon"><MdSip /></span>
              <span className="menu-title">SIP / STP</span>
             
            </NavLink>
          </li> */}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
