import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "../pages/login";
const ownerArr = [
  "/restaurant-lists",
  "/create-manager",
  "/restaurant-images",
  "/category",
  "/sub-category",
  "/menu",
  "/menu-images",
  "/sitting",
  "/time-slots",
  "/add-update-global-category",
  "/offer",
  "/restaurant-edit",
  "/orders",
  "/order-history",
];
const managerArr = [
  "/restaurant-images",
  "/category",
  "/sub-category",
  "/menu",
  "/menu-images",
  "/sitting",
  "/time-slots",
  "/offer",
];
export const authenticate = (url, components) => {
  let data = JSON.parse(localStorage.getItem("user"));
  if (data) {
    if (data?.role === "SUPER_ADMIN") {
      return <Route element={components} path={url} />;
    } else if (data.role === "OWNER") {
      let isPermitted = ownerArr.includes(url);
      if (isPermitted) {
        return <Route element={components} path={url} />;
      } else {
        return <Route path="/login" element={<Login />} />;
      }
    } else if (data.role === "MANAGER") {
      let isPermitted = ownerArr.includes(url);
      if (isPermitted) {
        return <Route element={components} path={url} />;
      }
    }
  } else {
    return <Route path="/login" element={<Login />} />;
  }
};
