import { React, useEffect, useState } from 'react';
import { FaEdit, FaTrash } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";

import { MDBDataTable, MDBInput } from 'mdbreact';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api, baseUrl } from '../url';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { selectCreater } from '../utils/custom-button';
import { Http } from '../http-dragon';
import Confirmation from '../components/confirmation';
const Restaurant_List = () => {
  const navigate = useNavigate()
  const [ownerList, setOwnerList] = useState([])
  const [ownerId, setOwnerId] = useState('')
  const [restaurantList, setRestaurantList] = useState([])
  const [isConfimation, setIsConfimation] = useState(false)
  const [confirmationMsg, setConfirmationMsg] = useState("")
  const [deleteId, setDeleteId] = useState({})
  const [loginData,setLoginData] = useState(null)
  const data = {
    columns: [
      {
        label: 'Date',
        field: 'createdAt',
        sort: 'asc',
        width: "500"
      },
      {
        label: 'GST',
        field: 'gst',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Fssai',
        field: 'fssai',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Table',
        field: 'tablesQuantity',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Restaurant Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },

      {
        label: 'Edit',
        field: 'edit',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 150
      },

    ],
    rows: restaurantList
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"))
    setLoginData(data)
    if(data?.role === "OWNER"){
      setLoginData(data)
      data.value = data.ownerid
      getRestaurent(data)
    }else if(data?.role === "SUPER_ADMIN"){
      fetchOwner()
    }
  }, [])

  const fetchOwner = async () => {
    Http.loader(true)
      axios.get(baseUrl + api.getOwners).then(res => {
        Http.loader(false)
        setOwnerList(res.data.owners)
        selectCreater(res.data.owners)
      }).catch (error=> {
      Http.loader(false)
      setOwnerList([])
      toast.error(error.response.data.msg)
    })
    
  }


  const getRestaurent = (e) => {
    console.log("rr", e.value);
    
    setOwnerId(e.value)
    Http.loader(true)

      axios.post(baseUrl + api.getRestaurantByOwner, { ownerID: e.value }).then(res => {
        setRestaurantList(res.data?.restaurants)
        //  selectCreater(res.data.owners)
        Http.loader(false)
        addButton(res.data?.restaurants)
  
    }).catch (error=> {
      Http.loader(false)
      setRestaurantList([])
      console.log("==",error);
      
      toast.error(error.response.data.message)
    })
  }
const deleteData =async (_id,status, owner)=>{
  try {
    Http.loader(true)
    const res = await axios.post(baseUrl + api.activeOrDeActiveRestaurant, deleteId)
    Http.loader(false)
    getRestaurent({ value: deleteId?.owner })
    toast.success(res?.data?.message)
  } catch (error) {
    Http.loader(false)
    toast.error(error?.response?.data?.message)
  }
}
  const activeOrDeActive = async (_id, status, owner) => {
    setIsConfimation(true)
    setConfirmationMsg(`Are you sure you want to ${status ? "DeActive" : "Active"} ?`)
    setDeleteId({restaurantID:_id, status:!status, owner}) 
  }

  const addButton = (array) => {
    array.forEach(element => {
      element.edit = <div to="/restaurant-edit" className="btn btn-primary pt-0 pb-1" onClick={() => editOwner(element._id)}><FaEdit /></div>
      element.delete = <div className={`btn btn-${element?.active ? "danger" : "success"} pt-0 pb-1`} onClick={() => activeOrDeActive(element._id, element?.active, element?.owner)}> {element?.active ? "De-Activate" : "Activate"}</div>
    });
  }

  const editOwner = (_id) => {
    navigate("/restaurant-edit", { state: { _id } })
  }
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiDetail size='20' />

                        </span>
                      </span>
                      Restaurant List
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loginData?.role === "SUPER_ADMIN"? <div className="col-12 col-md-4 ">
            <label htmlFor="applicant"><b>Select Owner</b></label>
            <Select
              options={ownerList}
              name='owner'
              onChange={getRestaurent}
            />
          </div>:""}
          
          <section className='px-4 mt-5'>

            <div className="row">
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <MDBDataTable
                  data={data}
                  responsive
                />

              </div>
            </div>
          </section>
        </div>
      </main>
      <Confirmation show={isConfimation} setShow={setIsConfimation} message={confirmationMsg}deleteData={deleteData} />
    </>
  );
}
export default Restaurant_List;