import React,{createContext,useState} from "react";

const OrderContext = createContext()

const RealTimeOrderProvider = ({children}) =>{
  const [realTimeOrder,setRealTimeOrder] = useState(null)

   
  return (
    <OrderContext.Provider value={{realTimeOrder,setRealTimeOrder}}>
         {children}
    </OrderContext.Provider>
  )
}

export {RealTimeOrderProvider,OrderContext}